import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/core/Checkbox.atom';
import { Icon } from '@seeqdev/qomponents';
import { LockErrorOutputV1 } from '@/sdk';
import { GlobalItems } from '@/accessControl/GlobalItems.atom';
import { formatTime, nanosToMillis } from '@/datetime/dateTime.utilities';
import { sqWorksheetStore } from '@/core/core.stores';

export interface LockedMetadataIF {
  lockedAt: number;
  lockedById: string;
  lockedByUsername: string;
}

interface AclModalLockTabProps {
  checkboxHandler: (isChecked: boolean) => void;
  understandLockingIsPermanent: boolean;
  lockErrors: LockErrorOutputV1[] | undefined;
  isLocked: boolean;
  lockedMetadata: LockedMetadataIF | undefined;
  userHasManagePermission: boolean;
  closeModalHandler: () => void;
}

export const AclModalLockTab: React.FunctionComponent<AclModalLockTabProps> = ({
  checkboxHandler,
  understandLockingIsPermanent,
  lockErrors,
  isLocked,
  lockedMetadata,
  userHasManagePermission,
  closeModalHandler,
}) => {
  const { t } = useTranslation();

  const [metadataTimestamp, setMetadataTimestamp] = useState('unknown');
  const [metadataLockedByUsername, setMetadataLockedByUsername] = useState('unknown');

  useEffect(() => {
    if (lockedMetadata !== undefined) {
      const lockedAtTime = formatTime(nanosToMillis(lockedMetadata.lockedAt), sqWorksheetStore.timezone);

      setMetadataTimestamp(lockedAtTime);
      setMetadataLockedByUsername(lockedMetadata.lockedByUsername);
    }
  }, [lockedMetadata]);

  const LockAnalysis = (
    <div className="flexRowContainer flexFill mt15 overflowVisible" id="aclGrid">
      {!lockErrors && userHasManagePermission ? (
        <>
          <div className="p10">
            <Icon
              icon="fa-exclamation-triangle"
              type="warning"
              testId="show-error-fallback-popover"
              extraClassNames="mr5"
            />
            {t('ACCESS_CONTROL.LOCKING.WARNING_1')}
          </div>
          <p>{t('ACCESS_CONTROL.LOCKING.DESCRIPTION')}</p>
          <div className="p10">
            <Checkbox
              id="understand-lock-is-permanent-checkbox"
              isChecked={understandLockingIsPermanent}
              onChange={() => checkboxHandler(!understandLockingIsPermanent)}
              label="ACCESS_CONTROL.LOCKING.UNDERSTAND"
            />
          </div>
        </>
      ) : !userHasManagePermission ? (
        <>{t('ACCESS_CONTROL.LOCKING.INSUFFICIENT_PERMISSION_TO_LOCK')}</>
      ) : null}

      <div className="flexRowContainer justify-content-center w-100 stdBorder mt5 mb30">
        <GlobalItems lockErrors={lockErrors ?? []} />
      </div>
    </div>
  );

  const LockedAnalysisMetadata = (
    <div className="mt50">
      <p>{t('ACCESS_CONTROL.LOCKING.IS_LOCKED')}</p>
      <div>
        <b>{t('ACCESS_CONTROL.LOCKING.LOCKED_ON_DATE')}</b>
        {metadataTimestamp}
      </div>
      <div>
        <b>{t('ACCESS_CONTROL.LOCKING.LOCKED_BY_USER')}</b>
        {metadataLockedByUsername}
      </div>
    </div>
  );

  return (
    <>
      {isLocked && LockedAnalysisMetadata}
      {!isLocked && LockAnalysis}
    </>
  );
};
