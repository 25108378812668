import { WORKSHEET_VIEW, WorksheetView } from '@/worksheet/worksheet.constants';
import { Actions, DockLocation, Model } from 'flexlayout-react';
import {
  assetGroupTabId,
  assetGroupTabJson,
  calculationHierarchyTabId,
  calculationHierarchyTabJson,
  capsulePanelTabId,
  capsulePanelTabJson,
  commentsTabId,
  dataPanelTabId,
  detailsPanelTabId,
  formulaHelpTabId,
  formulaHelpTabJson,
  journalPanelTabId,
  journalTabId,
  mobileOrganizerLayoutJSON,
  mobileWorksheetLayoutJSON,
  organizerLayoutJson,
  propertiesTabId,
  SIDE_PANEL_INITIAL_WIDTH,
  tableDefinitionTabId,
  tableDefinitionTabJson,
  tempVantageLayoutJson,
  toolsPanelTabId,
  trendTabId,
  trendTabSetId,
  worksheetLayoutJSON,
  worksheetsTabId,
} from '@/main/layoutDefinitions';
import { isSystemTest } from '@/core/utilities';
import {
  resetJournalToolbar,
  resetLayout,
  setAssetGroupEditorVisible,
  setFormulaInTab,
  setJournalTabVisible,
  setLayoutLocked,
  setTableDefinitionEditorVisible,
  setToolsTabVisible,
  setTrendVisible,
} from '@/main/layout.actions';
import { isMobileDevice } from '@/utilities/utilities';
import { getWorkbookDisplayMode } from '@/main/routing.utilities';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import _ from 'lodash';

/**
 * Determines if a particular worksheet view is currently selected for display.
 * target - A worksheet target (i.e. WORKSHEET_TARGET.DATA_PANEL or WORKSHEET_TARGET.WORKSHEET).
 * key - A worksheet key (e.g. WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.EXPLORE, etc.). If this
 *                       value is not defined, then only the target parameter is used to determine if
 *                       the view is selected.
 * returns True if a view matching the supplied target and key is selected, false otherwise or
 *                    if transitioning to a worksheet.
 */
export function isSelectedView(target: string, key?: string, view?: WorksheetView): boolean {
  const currentTarget = view?.target;
  const currentKey = view?.key;
  return currentTarget === target && (!key || currentKey === key);
}

export const detailsAndCapsulePanelVisibilityByView = (view: string) => {
  return { details: true, capsules: view !== WORKSHEET_VIEW.TREEMAP && view !== WORKSHEET_VIEW.TABLE };
};

export const getLayoutStorageIdentifier = (viewOnlyMode: boolean, isReportBinder: boolean, isVantage: boolean) => {
  let identifier = (isReportBinder && 'organizerLayout') || (isVantage && 'tempVantageLayout') || 'worksheetLayout';
  if (viewOnlyMode) {
    identifier += '_viewOnly';
  }
  if (isMobileDevice()) {
    identifier += '_mobile';
  }
  return identifier;
};

export const getInitialLayout = (isReportBinder: boolean, isVantage: boolean) => {
  if (isMobileDevice()) {
    return (
      (isReportBinder && mobileOrganizerLayoutJSON) || (isVantage && tempVantageLayoutJson) || mobileWorksheetLayoutJSON
    );
  }
  return (isReportBinder && organizerLayoutJson) || (isVantage && tempVantageLayoutJson) || worksheetLayoutJSON;
};

export const addCapsulePanel = (layoutJson: Model) => {
  if (!layoutJson.getNodeById(capsulePanelTabId)) {
    const detailsPanelParent = layoutJson.getNodeById(detailsPanelTabId)?.getParent();
    detailsPanelParent &&
      layoutJson.doAction(Actions.addNode(capsulePanelTabJson, detailsPanelParent.getId(), DockLocation.RIGHT, -1));
  }
  return layoutJson;
};

export const addCalculationHierarchyPanel = (layoutJson: Model) => {
  if (!layoutJson.getNodeById(calculationHierarchyTabId)) {
    const trendPanelParent = layoutJson.getNodeById(trendTabId)?.getParent();
    trendPanelParent &&
      layoutJson.doAction(
        Actions.addNode(calculationHierarchyTabJson, trendPanelParent.getId(), DockLocation.CENTER, -1),
      );
  }
  return layoutJson;
};

export const getParentId = (layoutJson: Model, tabSetId: string) => {
  const tab = layoutJson.getNodeById(tabSetId);
  const children = tab?.getParent()?.getChildren();
  const types = children?.map((child) => child.getType());
  return _.uniq(types).length === 1 && tab?.getParent()?.getId() !== 'root' ? tab?.getParent()?.getId() : tabSetId;
};

export const removeFormulaHelpTab = (layoutJson: Model) => {
  // when deleting the formula help tab we have to make sure we set the parent panel with to avoid jumping as it
  // re-creates tab set when removing the help tab
  const tabSetParent = layoutJson.getNodeById(formulaHelpTabId)?.getParent();
  const parentId = getParentId(layoutJson, tabSetParent?.getId() || '');
  const formulaHelpPanelParent = layoutJson.getNodeById(parentId!);
  const width = formulaHelpPanelParent?.getRect()?.width ?? 100;
  layoutJson.doAction(Actions.deleteTab(formulaHelpTabId));
  const newToolPanelParent = layoutJson.getNodeById(parentId!)?.getParent();
  newToolPanelParent && layoutJson.doAction(Actions.updateNodeAttributes(newToolPanelParent.getId(), { width }));
};
export const addFormulaHelpTab = (layoutJson: Model) => {
  if (layoutJson && !layoutJson.getNodeById(formulaHelpTabId)) {
    const toolPanelParent = layoutJson.getNodeById(toolsPanelTabId)?.getParent();
    if (toolPanelParent) {
      const width =
        toolPanelParent?.getRect()?.width !== 0 ? toolPanelParent?.getRect().width : SIDE_PANEL_INITIAL_WIDTH;

      layoutJson.doAction(Actions.addNode(formulaHelpTabJson, toolPanelParent.getId(), DockLocation.BOTTOM, 1));
      const tabSetParent = layoutJson.getNodeById(formulaHelpTabId)?.getParent();
      tabSetParent &&
        layoutJson.doAction(
          Actions.updateNodeAttributes(tabSetParent.getId(), { height: window.screen.availHeight / 4 }),
        );
      const newToolPanelParent = layoutJson.getNodeById(toolsPanelTabId)?.getParent();
      const row = newToolPanelParent?.getParent();
      row && layoutJson.doAction(Actions.updateNodeAttributes(row.getId(), { width }));
    }
  }

  return layoutJson;
};

export const inSameTabset = (tab1: string, tab2: string, layoutModel: Model) => {
  const tab1Parent = layoutModel?.getNodeById(tab1)?.getParent();
  const tab2Parent = layoutModel?.getNodeById(tab2)?.getParent();
  return tab1Parent?.getId() === tab2Parent?.getId();
};

export const getParentTabSetCount = (tabId: string, layoutModel: Model) => {
  return layoutModel?.getNodeById(tabId)?.getParent()?.getChildren()?.length;
};

export const sideTabsArePristine = (layoutModel: Model) => {
  return (
    (inSameTabset(dataPanelTabId, toolsPanelTabId, layoutModel) &&
      inSameTabset(toolsPanelTabId, journalPanelTabId, layoutModel) &&
      getParentTabSetCount(dataPanelTabId, layoutModel) === 3) ||
    (inSameTabset(propertiesTabId, commentsTabId, layoutModel) &&
      getParentTabSetCount(propertiesTabId, layoutModel) === 2)
  );
};

export const initializeLayoutModel = (
  layoutJson: Model,
  worksheetsCollapsed: boolean,
  view: WorksheetView,
  isReportBinder: boolean,
  isVantage: boolean,
  addAssetGroupTab: boolean,
  addTableDefinitionViewTab: boolean,
  conditionInDetailsPanel: boolean,
  calculationHierarchyInTab: boolean,
  formulaInTab: boolean,
  selectedTab: string | undefined,
  isLocked: boolean,
): Model => {
  // users may forget that they had expanded a tabSet and then find themselves looking for lost tabs - to avoid
  // this we ensure all tabSets are minimized on load (or reset)
  const maximizedTabSet = layoutJson.getMaximizedTabset();
  if (maximizedTabSet) {
    layoutJson.doAction(Actions.maximizeToggle(maximizedTabSet.getId()));
  }
  if (worksheetsCollapsed) {
    const worksheetTab = layoutJson.getNodeById(worksheetsTabId);

    if (worksheetTab) {
      const worksheetTabParent = worksheetTab.getParent();
      worksheetTabParent &&
        layoutJson.doAction(
          Actions.updateNodeAttributes(worksheetTabParent.getId(), {
            selected: -1,
          }),
        );
    }
  }

  if (!isReportBinder && !isVantage) {
    // then it's a reset layout call - we have to check for details and capsule panels and add them if needed:
    const { capsules } = detailsAndCapsulePanelVisibilityByView(view?.key);
    if (isSystemTest()) {
      const trendTabSetParentId = layoutJson.getNodeById(trendTabSetId)?.getId();
      trendTabSetParentId &&
        layoutJson.doAction(
          Actions.updateNodeAttributes(trendTabSetParentId, {
            weight: 90,
          }),
        );
    }

    if (capsules && conditionInDetailsPanel && !layoutJson.getNodeById(capsulePanelTabId)) {
      addCapsulePanel(layoutJson);
    }
  }

  const trendParentTabSetId = layoutJson.getNodeById(trendTabId)?.getParent()?.getId();

  if (addAssetGroupTab) {
    if (!layoutJson.getNodeById(assetGroupTabId) && trendParentTabSetId) {
      layoutJson.doAction(Actions.addNode(assetGroupTabJson, trendParentTabSetId, DockLocation.CENTER, -1));
    }
  } else {
    layoutJson.doAction(Actions.deleteTab(assetGroupTabId));
  }
  if (addTableDefinitionViewTab) {
    if (!layoutJson.getNodeById(tableDefinitionTabId) && trendParentTabSetId) {
      layoutJson.doAction(Actions.addNode(tableDefinitionTabJson, trendParentTabSetId, DockLocation.CENTER, -1));
    }
  } else {
    layoutJson.doAction(Actions.deleteTab(tableDefinitionTabId));
  }

  if (calculationHierarchyInTab) {
    if (!layoutJson.getNodeById(calculationHierarchyTabId) && trendParentTabSetId) {
      layoutJson.doAction(Actions.addNode(calculationHierarchyTabJson, trendParentTabSetId, DockLocation.CENTER, -1));
    }
  } else {
    layoutJson.doAction(Actions.deleteTab(calculationHierarchyTabId));
  }

  if (isLocked) {
    layoutJson.doAction(Actions.deleteTab(dataPanelTabId));
  }

  if (formulaInTab) {
    addFormulaHelpTab(layoutJson);
  } else {
    removeFormulaHelpTab(layoutJson);
  }

  // make sure the visibility listeners are always added
  layoutJson.getNodeById(trendTabId)?.setEventListener('visibility', ({ visible }) => {
    setTrendVisible(visible);
  });

  layoutJson.getNodeById(assetGroupTabId)?.setEventListener('visibility', ({ visible }) => {
    setAssetGroupEditorVisible(visible);
  });

  layoutJson.getNodeById(tableDefinitionTabId)?.setEventListener('visibility', ({ visible }) => {
    setTableDefinitionEditorVisible(visible);
  });

  layoutJson.getNodeById(journalTabId)?.setEventListener('visibility', ({ visible }) => {
    setJournalTabVisible(visible);
  });

  layoutJson.getNodeById(toolsPanelTabId)?.setEventListener('visibility', ({ visible }) => {
    setToolsTabVisible(visible);
  });

  if (getWorkbookDisplayMode() === WORKBOOK_DISPLAY.VIEW) {
    layoutJson.doAction(Actions.selectTab(isReportBinder ? propertiesTabId : journalTabId));
  } else if (!isMobileDevice() && selectedTab && sideTabsArePristine(layoutJson))
    layoutJson.doAction(Actions.selectTab(selectedTab));

  return layoutJson;
};

export const doResetLayout = () => {
  setLayoutLocked(false);
  resetLayout(true);
  resetJournalToolbar(true);
  setFormulaInTab(false);
};
