import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { AceOutputV1 } from '@/sdk/model/AceOutputV1';
import { auditingAllUsersCanRead } from '@/services/systemConfiguration.utilities';
import { CapabilitiesEnum } from '@/sdk/model/UserOutputV1';

/**
 * Determines if the Audit Trail logs can be viewed by the current user
 *
 * @return true if the current user can view Audit Trail Logs,
 * otherwise false even if the setting is null or undefined
 */
export function canReadAuditTrail(): boolean {
  return (
    // TODO CRAB-41986: Remove the config check once the config is removed
    auditingAllUsersCanRead() === true ||
    !!sqWorkbenchStore.currentUser.capabilities?.includes(CapabilitiesEnum.AuditTrailCapability)
  );
}

/**
 * Determines if the current user can read the item
 *
 * @param item - an item
 * @returns true if the user can read the item, false otherwise
 */
export function canReadItem(item: any): boolean {
  return isAdmin() || !!item?.effectivePermissions?.read;
}

/**
 * Determines if the current user can write the item
 *
 * @param item - an item
 * @returns true if the user can write the item, false otherwise
 */
export function canWriteItem(item: any): boolean {
  return (isAdmin() || !!item?.effectivePermissions?.write) && !item?.isLocked;
}

/**
 * Determines if the current user can annotate the item
 *
 * @param item - an item
 * @returns true if the user can annotate the item, false otherwise
 */
export function canAnnotateItem(item: any): boolean {
  // Users in Seeq can annotate an item if they have permission to read it
  return isAdmin() || !!item?.effectivePermissions?.read;
}

/**
 * Determines if the current user can manage the item (i.e. view and change ACL permissions)
 *
 * @param item - an item
 * @returns true if the user can manage the item, false otherwise
 */
export function canManageItem(item: any): boolean {
  return isAdmin() || !!item?.effectivePermissions?.manage;
}

/**
 * Determines if a user has exclusive access
 *
 * @param acl - an access control list
 * @param userId - a user ID
 * @returns true if exclusive access; false otherwise
 */
export function userHasExclusiveAccess(acl: AceOutputV1[], userId = sqWorkbenchStore.currentUser.id): boolean {
  return !_.chain(acl)
    .reject((ace) => ace?.identity?.id === userId || !_.some(ace.permissions))
    .some()
    .value() as boolean;
}

/**
 * Determines if the specified workbook can be modified by the current user.
 *
 * @param  workbook - The workbook to test
 * @param  disallowArchived - if true prevent modification of archived workbooks
 * @return True if it can be modified, otherwise false
 */
export function canModifyWorkbook(workbook: any, disallowArchived = true): boolean {
  if (disallowArchived && workbook?.isArchived) {
    return false;
  }

  return canWriteItem(workbook);
}

export const isWorkbookLocked = (workbook: any): boolean => workbook?.locked;

/**
 * Allows the user to do something if they are an administrator.
 *
 * @return True if user is an administrator, false otherwise
 */
export function isAdmin(): boolean {
  return !!sqWorkbenchStore.currentUser.isAdmin;
}

/**
 * @return True if current user has the UserAdministrationCapability
 */
export function hasUserAdminCapability(): boolean {
  return sqWorkbenchStore.currentUser.capabilities.includes(CapabilitiesEnum.UserAdministrationCapability);
}

/**
 * @return True if current user has the DatasourceAdministrationCapability
 */
export function hasDatasourceAdminCapability(): boolean {
  return sqWorkbenchStore.currentUser.capabilities.includes(CapabilitiesEnum.DatasourceAdministrationCapability);
}

export function showAdministrationMenuItem(): boolean {
  return isAdmin() || hasUserAdminCapability() || hasDatasourceAdminCapability();
}

/**
 * Allows the user to do something if the value at the supplied property path matches the current user ID.
 *
 * @param userIdPropertyPath - property path to a user ID
 * @return True if the value at the supplied property path matches the current user ID, false otherwise
 */
export function hasCurrentUserId(item: any, userIdPropertyPath: string): boolean {
  return _.get(item, userIdPropertyPath) === sqWorkbenchStore.currentUser.id;
}
