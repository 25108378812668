// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { IconProps } from '@seeqdev/qomponents/dist/Icon/Icon.types';
import { formatTime, TIME_FORMAT_NUMERICAL_SHORT } from '@/datetime/dateTime.utilities';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import {
  formatJournalReportContent,
  getItemIcon,
  getWorkbook,
  translateBreadcrumb,
} from '@/utilities/homescreen.utilities';
import { sqHomeScreenStore, sqWorksheetStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { JOURNAL_OR_REPORT, UNNAMED_REPORT } from '@/homescreen/homescreen.constants';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';

interface NameCellProps {
  item: any;
  showBreadcrumbs: boolean;
  extraClassNames?: string;
  isCurrentWorkbook?: boolean;
}

export const NameCell: React.FunctionComponent<NameCellProps> = (props) => {
  const { item, showBreadcrumbs, extraClassNames, isCurrentWorkbook = false } = props;
  const { t } = useTranslation();

  const [journalReportName, setJournalReportName] = useState<string>();
  const [journalReportContent, setJournalReportContent] = useState<string>();
  const isJournalReport = _.includes(JOURNAL_OR_REPORT, item.type);
  const searchText = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.searchParams.textSearch);
  const isAllowedLanguageCharacter = (word: string): boolean => /^[^-_/.,')'\\p{Alnum} ]+/.test(word);
  const isFoundInSearchText = (word: string): boolean =>
    ((_.includes(_.toLower(searchText), _.toLower(word)) && isAllowedLanguageCharacter(word)) ||
      _.includes(_.toLower(word), _.toLower(searchText))) &&
    searchText;

  useEffect(() => {
    if (isJournalReport) {
      getWorkbook(item.workbookId).then((workbook) => {
        const itemWorksheet = _.find(workbook?.worksheets, { worksheetId: item.worksheetId });
        if (itemWorksheet) {
          setJournalReportName(`${workbook.name} - ${itemWorksheet.name}`);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (item.name !== t('UNNAMED') && item.name !== UNNAMED_REPORT && isJournalReport) {
      formatJournalReportContent(searchText, item.id).then((content) => setJournalReportContent(content));
    }
  }, [searchText, item]);

  const renderBreadcrumbs = (crumbs) => (
    <span data-testid="itemBreadcrumb" className="xsmall">
      {_.join(
        _.map(crumbs, (crumb) => translateBreadcrumb(crumb)),
        ' » ',
      )}
    </span>
  );

  const renderJournalReportSearchResults = (content: string) => (
    <span className="small">
      {_.map(_.split(content, /[, ]+/), (word) => {
        return (
          <span className={isFoundInSearchText(word) ? 'text-bold' : ''}>
            <ContainerWithHTML content={`${word} `} />
          </span>
        );
      })}
    </span>
  );

  const renderNameWithLockedStatus = (item: any) => (
    <div className="flexColumnContainer">
      {item.name}
      {item.locked ? <Icon icon="fa-lock" extraClassNames="ml5" /> : <></>}
    </div>
  );

  return (
    <div className={classNames('flexRowContainer', extraClassNames)}>
      {showBreadcrumbs && renderBreadcrumbs(item.ancestors)}
      <span data-testid="itemName">{journalReportName ?? renderNameWithLockedStatus(item)}</span>
      {isJournalReport && (
        <span data-testid="journalReportPreview">{renderJournalReportSearchResults(journalReportContent)}</span>
      )}
      {isCurrentWorkbook && <span className="xsmall">({t('EXPLORER_MODAL.CURRENT_WORKBOOK')})</span>}
    </div>
  );
};

interface HomeScreenIconCellProps {
  item: any;
  spinning?: boolean;
}

export const HomeScreenItemIconCell: React.FunctionComponent<HomeScreenIconCellProps> = (props) => {
  const { item, spinning = false } = props;
  const itemIcon = getItemIcon(props.item);

  return (
    <div data-testid={`icon_${item.id}`}>
      <IconWithSpinner spinning={spinning} icon={classNames('workbookIcon', 'workbenchDisplay', itemIcon)} />
    </div>
  );
};

interface DateCellProps {
  item: any;
  accessor: string;
}

export const DateCell: React.FunctionComponent<DateCellProps> = (props) => {
  const { item, accessor } = props;

  if (!item[accessor]) {
    return null;
  }

  return <span>{formatTime(item[accessor], sqWorksheetStore.timezone, TIME_FORMAT_NUMERICAL_SHORT)}</span>;
};

export const ProgressCell: React.FunctionComponent<{ percentage: number }> = ({ percentage }) => (
  <div className="flexColumnContainer flexCenter">
    <span className="dataStatusIcon verticalAlignMiddle">
      <CircularProgressbar value={percentage} text={_.toString(percentage)} />
    </span>
  </div>
);

export const IconCell: React.FunctionComponent<IconProps> = (props) => (
  <div className="flexColumnContainer flexCenter">
    <Icon {...props} />
  </div>
);
